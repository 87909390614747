import './AdminSection.css'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut,FiEdit } from 'react-icons/fi'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { Link, NavLink, useNavigate ,useParams} from 'react-router-dom';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField, Typography, Container,
  InputLabel, MenuItem, FormControl, Select, CircularProgress,DialogContentText,Skeleton,Rating
} from '@mui/material';
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../../Constants/Constant'
import ProductCard from '../../../function/ProductCollectionCard'
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import {CiRead,CiUnread} from 'react-icons/ci'
import ImageGallery from 'react-image-gallery';
import { AiFillDelete} from 'react-icons/ai'


// import { ContextFunction } from '../Context/Context';
import { toast } from 'react-toastify';
// import { getCart, getWishList, handleLogOut, handleClickOpen, handleClose, Transition } from '../Constants/Constant'

const AdminSection = (props) => {

    // const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [sectionsData, setSectionsData] = useState([])
    const [loading,setLoading] = useState(false);
    const [loadingData,setLoadingData] = useState(false);
    const [step, setStep] = useState({page:1,title:'home'})
    const [productInfo, setProductInfo] = useState({
      name: "",
       });
    let authToken = localStorage.getItem('Authorization')
    const [openModal,setOpenModal] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [deleteModal,setDeleteModal]= useState(false);
    const [holdData,setholdData]= useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [getData,setGetData] = useState(null);
    const [closeSearch,setCloseSearch]= useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [productOld, setProductOld] = useState([])
    const [seletedData,setSeletedData] = useState(null)
    const [section , setSection] = useState(null)
    const [addProduct,setAddProduct]= useState([]);
    const [loadPublish,setLoadPublish]= useState(false);
    const [publish,setPublish]= useState(false);
    const [images, setImages]= useState([])
    let navigate = useNavigate()



    useEffect(() => {
      setLoading(true)
      getAllProduct()
      getAllCollection()
        window.scroll(0, 0)
    }, [])


    const getAllProduct = async () => {
 
      if (authToken !== null) {
          const { data } = await axios.get(`${process.env.REACT_APP_COLLECTION_ADMIN}`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('-----------------COLLECTION---------data',data)
        setLoading(false)
        setSectionsData(data.data);
       
      }else{

      }

  }

  const getAllCollection = async () => {
    // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
    if (authToken !== null) {
        const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

            {
                headers: {
                    'Authorization': authToken
                }
            })
      // console.log('-----------------4---------data',data)
      setLoading(false)
      setProductData(data);
      setProductOld(data)
    }else{
      const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

            {
                headers: {
                    'Authorization': authToken
                }
            })
      // console.log('---------------5-------data',data)
      setLoading(false)
      setProductData(data);
      setProductOld(data)
    }
 
}
    
  // const [productInfo, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
  const handleOnchange = (e) => {
      setProductInfo({ ...productInfo, [e.target.name]: e.target.value })
  }

  const handleClose = () => {
    setOpenAlert(false);
    setOpenEdit(false)
    setDeleteModal(false)
    setDeleteModal(false)
};

const Showdetail = (data) =>{
  // console.log('data---------------->',data)
  setGetData(data)
  productInfo.name = data.name
  if(data.publish === undefined ){
    setPublish(false)
    }else{
      if(data.publish === false ){
        setPublish(false)
      }else{
        setPublish(true)
      }
    }
  setOpenEdit(true)
}

const ShowDelete = (data)=>{
    setDeleteModal(true)
    setGetData(data)
}

const GoToCollection = () =>{
  navigate('/list')
}

  const getDropDown = () =>{
    props.getDropDown()
  }

  const formattedData = (data, array) => data.map((obj, i) => (  
    {
      ...obj,
      quantity: array[i]
    }
  ));

  const  moneyFormat =(price, sign = 'N') =>{
    const pieces = parseFloat(price).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return sign + pieces.join('')
  }
   

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',productInfo.name)
    try {
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
        }
        else {
            const { data } = await axios.post(`${process.env.REACT_APP_COLLECTION_ADMIN}`,
                {
                    name: productInfo.name,
                    // owner:
                    company :'store'
                    // description: productInfo.description,
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
              toast.success("Collection created", { autoClose: 500, theme: 'colored' })
              setLoadingData(false)
              setSectionsData([...sectionsData ,data.data])
                setProductInfo({
                    name: "",
                   });
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
        }
    } catch (error) {
      setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }

}


const UpdateSubmit = async (e) =>{
  e.preventDefault()
  setLoadingData(true)
  // console.log('-----------------4---------data',productInfo.name)
  try {
      if (!productInfo.name ) {
          toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
          setLoadingData(false)
      }
      else {
          const { data } = await axios.put(`${process.env.REACT_APP_COLLECTION_ADMIN}/${getData._id}`,
              {
                  name: productInfo.name,
                  company :'store'
              }, {
              headers: {
                  'Authorization': authToken
              }
          })
          setOpenAlert(false);
          // console.log('-----------------4---------data',data)
          if (data.status === true) {
            toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
              setProductInfo({
                  name: "",
                 });
             setLoadingData(false)
             setOpenEdit(false)
          }
          else {
            setLoadingData(false)
              toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
          }
      }
     } catch (error) {
       setLoadingData(false)
      toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
  }
}

const deleteCollection = async ()=>{
  setLoadingData(true)
  // console.log('-----------------4---------data',getData)
     try  {
          const { data } = await axios.delete(`${process.env.REACT_APP_COLLECTION_ADMIN}/${getData._id}`,
               {
              headers: {
                  'Authorization': authToken
              }
          })
          // setOpenAlert(false);
          // console.log('-----------------4---------data',data)
          if (data.status === true) {
            toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
             setSectionsData(sectionsData.filter(item => item._id !== getData._id));
             setLoadingData(false)
             handleClose(false)
          }
          else {
            setLoadingData(false)
              toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
          }
     } catch (error) {
       setLoadingData(false)
      toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
  }
}

const GoToProduct = (data)=>{
  setSeletedData(data)
  setAddProduct(data.products)
  setStep({page:2,title:'product'})
}

const handleSearch = async (event) => {
  // console.log("data ----false--article----3->",event.target.value)
  try {

  setSearchTerm(event.target.value);
  setCloseSearch(true)
     if(event.target.value.length >= 2){

            const { data } = await axios.get(`${process.env.REACT_APP_PRODUCT}/product/search/?search=${event.target.value}`,
           {
                headers: {
                    'Authorization': authToken
                }
            })

            // console.log('--------------res---4---------',data.data            )
            if (data.status === true) {
              if(data.data === undefined){

              }else{
                setProductData(data.data)
              }
            }

          }

          } catch (error) {
            toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
        }
};

const handleClickShowPassword = () =>{
  // console.log("data ----false--article----3->",)
  setCloseSearch(!closeSearch)
  setProductData(productOld)
  setSearchTerm('')
}

const sendData = async(dataSender) =>{

try {
  let ids = addProduct.find(x => x._id === dataSender._id)
  
     if(ids === null || ids === undefined){
      // holdData.quantity = productQuantity
          setAddProduct([...addProduct, dataSender])
              const { data } = await axios.post(`${process.env.REACT_APP_COLLECTION_ADMIN}/add/${seletedData._id}`,
              {
                product:dataSender._id,
                // quantity: productQuantity ,
                section:seletedData._id,
              },{
                  headers: {
                      'Authorization': authToken
                  }
              })
            
              // console.log('------------res-----4---------data',data)
              if (data.status === true) {
              
                // setSectionsData(data.data)
                toast.success("added", { autoClose: 500, theme: 'colored' })
              
                } else {
                  toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
                }

          }else{
            if (ids._id===dataSender._id) {
              toast.error("Sorry this quiz is already added", { autoClose: 500, theme: 'colored' })
            } else {
              // console.log('-------not yet-----data',ids,dataSender._id)
              setAddProduct([...addProduct, dataSender])
                const { data } = await axios.post(`${process.env.REACT_APP_COLLECTION_ADMIN}/add/${seletedData._id}`,
                {
                  product:dataSender._id,
                  section:seletedData._id,
                },{
                    headers: {
                        'Authorization': authToken
                    }
                })
                if (data.status === true) {
                
                  setSectionsData(data.data)
                  toast.success("added", { autoClose: 500, theme: 'colored' })
                  
                }
              else {
                  toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
              }
          }
        }
      } catch (error) {
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
   }

}

const showData = (data)=>{
  setSeletedData(data)
  setSection(data)
  setAddProduct(data.products)
  const ArrayDATA = []
  var images = data.products[0]
        for (let i = 0; i < images.images.length; i++) {
          // console.log('data----------images------->',images.images[i].url)
              const newData ={
                      original:  images.images[i].url,
                      thumbnail:  images.images[i].url,
              }
              //  setImages([...images, newData])
        ArrayDATA.push(newData)
  }
  setImages(ArrayDATA)
  setStep({page:3,title:'show'})
  
}


const handleBack = ()=>{
  setStep({page:1,title:'home'})
  setSeletedData(null)
  setAddProduct([])
}


const ClickToShow =(data)=>{
  // setShowData(data)
  const ArrayDATA = []
  var images =  data.images
  for (let i = 0; i < data.images.length; i++) {
    // console.log('data----------images------->', data.images[i].url)
              const newData ={
                      original:  data.images[i].url,
                      thumbnail:  data.images[i].url,
              }
              //  setImages([...images, newData])
        ArrayDATA.push(newData)
    }
    setImages(ArrayDATA)
 }


const removeProductData =async (data)=>{
  setLoadingData(true)
      var removeBook = addProduct.filter(item => item._id !== data._id)

      setAddProduct(removeBook)
  // CountTotal(removeBook)

  const ArrayDATA = []
  // var images =  data.images
  var product = removeBook[0]
    if(removeBook.length === 0){
      setSection(null)
       }else{
      for (let i = 0; i < product.images.length; i++) {
        // console.log('data----------images------->', product.images[i].url)
                  const newData ={
                          original:  product.images[i].url,
                          thumbnail:  product.images[i].url,
                  }
                  //  setImages([...images, newData])
            ArrayDATA.push(newData)
          }
      }
 
    if(removeBook.length === 0){
      setImages([])
        }else{
        setImages(ArrayDATA)
    }
    
      try {
       const { data } = await axios.put(`${process.env.REACT_APP_COLLECTION_ADMIN}/${seletedData._id}`,
                {
                  products: removeBook,
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            setOpenAlert(false);
            if (data.status === true) {
              toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                // setProductInfo({
                //     name: "",
                    
                //    });
               setLoadingData(false)
               setOpenEdit(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
}



const dataForSwitch = async()=>{
         
  setLoadPublish(true)

  try {
  
        const { data } = await axios.post(`${process.env.REACT_APP_COLLECTION_ADMIN}/publish`,
            {
              id:getData._id,
                publish:!publish
            }, {
            headers: {
                'Authorization': authToken
            }
        })
        // setOpenAlert(false);
        // console.log('-----------------4---------data',data)
        if (data.status === true) {
          toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
          setLoadPublish(false)
          setPublish(!publish )
          setLoadPublish(false)
        }else {
          setLoadPublish(false)
            toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
        }
  
    } catch (error) {
    setLoadPublish(false)
    toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
}

}

  // getDropDown
  return (
           <div>
              {
                step.page === 1 ?
                <>
                <div>
                    <div className='collections-title'>
                         <button className="buttom-make collection-add" onClick={()=>setOpenAlert(!openAlert)}>
                           Add Collection
                         </button>
                          <h3>Collections</h3>
                    </div>
 
                {
                  sectionsData.map((item, index) => (
                   <div>
                     <div className={item.products.length  === 0 ?'collections-null':'collections'}>
                             {
                               item.products.length  === 0 ?
                                 null
                               :
                                 <div className='collection-show'>
                                     <a onClick={()=>showData(item)}>
                                       <img src={item.products[0].images[0].url} />
                                    </a>
                                 </div>
                             }
                             <div className={item.products.length  === 0 ?'collection-detail-null':'collectionRight'}>
                                    <div className='adminSectionprice'>
                                        <br/>
                                          <p >
                                           <a href='#' onClick={()=>GoToProduct(item)}><MdOutlineAdd style={{ fontSize: 30 }}/></a>
                                          <a href='#' onClick={()=>Showdetail(item)} href='#'> <FiEdit  style={{ fontSize: 28, marginTop: 7 }} /> </a>  
                                            <a  onClick={()=>ShowDelete(item)} href='#'> <AiFillDelete style={{ fontSize: 28 }} color='red'/> </a>  
                                          </p>
                                      </div>
                                               <h3><small> {item.name}</small></h3>
                                               <a onClick={()=>showData(item)}><h4>   {  item.products.length  === 0 ? <small>product Name</small>: null} </h4></a>
                                            {
                                              item.products.length  === 0 ?
                                              <button className="buttom-make collection-add-pro" onClick={()=>GoToProduct(item)}>
                                                <MdOutlineAdd style={{ fontSize: 29 }}/>
                                              </button>
                                              :
                                              null
                                            }
                                        {
                                          item.products.length  === 0 ?
                                           null
                                           :
                                          <div className='collection-list'>
                                           {
                                             item.products.map((item, index) => (
                                               <div className='collection-list-sub'>
                                                          <img src={item.images[0].url} />
                                               </div>
                                               ))
                                             }
 
                                          </div>
                                     }
                                </div>
                             </div>
                         </div>
                      ))
                  }
                     
                    
                </div>
 
                <Dialog
                     open={openEdit}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleClose}
                     aria-describedby="alert-dialog-slide-description"
                   >
                   {
                     getData === null ?
                     null
                     :
                     <form onSubmit={UpdateSubmit}>
                     <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                     <h4>Add Collections</h4>
                       <br/>
                           <div style={{width:'95%'}}>
                                 
                                 
                                <TextField label="Name" name='name'    value={productInfo.name}  onChange={handleOnchange} variant="outlined" fullWidth />
                                 
                           </div>

                           {
                            loadPublish === true ?
                            <Button loading variant="outlined">
                                Loading
                              </Button>
                            :
                            <>
                                {
                                  publish ?
                                    <Button variant='contained' className='all-btn ' style={{ width:'30%',marginTop:'5%' }}  color="success" startIcon={  <CiRead style={{ fontSize: 25 }}/>} onClick={(() => dataForSwitch())}> Publish </Button>
                                    :
                                    <Button variant='contained' className='all-btn ' style={{ width:'30%',marginTop:'5%' }}  startIcon={  <CiUnread style={{ fontSize: 25 }}/>} onClick={(() => dataForSwitch())}>  Unpublish</Button>
                                  }  
                             </> 
                            }
                          
                           {
                             loadingData === true?
                                 <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                      <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                 </div>
                               :
                             <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                               <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                               <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Update</Button>
                            </DialogActions>
                           }
                     </DialogContent>
                     </form>
                   }
                   
               </Dialog>
 
                   <Dialog
                   open={deleteModal}
                   TransitionComponent={Transition}
                   keepMounted
                   onClose={handleClose}
                   aria-describedby="alert-dialog-slide-description"
                 >
                

                 <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
                 <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                     <Typography variant='body1'>Do you want to delete this product?</Typography>
                 </DialogContentText>
             </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant='contained' endIcon={<AiFillDelete />} color='error' onClick={deleteCollection}>Delete</Button>
                    <Button variant='contained' color='primary'
                        onClick={() => handleClose()} endIcon={<AiFillCloseCircle />}>Close</Button>
                </DialogActions>

             </Dialog>
 
                <Dialog
                     open={openAlert}
                     TransitionComponent={Transition}
                     keepMounted
                     onClose={handleClose}
                     aria-describedby="alert-dialog-slide-description"
                   >
                     <form onSubmit={handleSubmit}>
                       <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                       <h4>Add Collections</h4>
                         <br/>
                             <div style={{width:'95%'}}>
                                   
                                   
                                             <TextField label="Name" name='name'  onChange={handleOnchange} variant="outlined" fullWidth />
                                   
                             </div>
                             {
                               loadingData === true?
                                   <div style={{marginTop:'5%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                       <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                   </div>
                                 :
                               <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                 <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                 <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Add</Button>
                             </DialogActions>
                             }
                       </DialogContent>
                     </form>
               </Dialog>
             </>  
             : step.page === 2?
             <>
                  <div className="adminSection-close">
                     <Button variant='contained'  color='error'   style={{marginRight:'1%',marginTop:'3%'}} onClick={() => handleBack()} endIcon={<AiFillCloseCircle />}>Close</Button>
                  </div>
                  <div className='adminSection-searchCollection'>
                      <div className="adminSection-searchBody">
                      
                      <TextField
                          id="search"
                        
                          label="Search Products"
          
                          value={searchTerm}
                          onChange={handleSearch}
                          className={'collectionList-search'}

                          
                      />
                      {closeSearch ?   <a onClick={handleClickShowPassword}><AiOutlineClose  style={{ fontSize: 25 , color:'red',marginTop:'2%',marginLeft:'2%'}}  /> </a>: null}
                      </div>
                      <div className="adminSection-collection">
                        <div>
                            {
                              sectionsData === null ?
                              null
                              :
                              <p>{seletedData.name}</p> 
                            }
                        </div>
                        <div>
                           {addProduct.length}
                        </div>
                      </div>
                  </div>
                

                  <div className="adminSection-product">
                  {
                    loading === true?
                      <div style={{paddingTop:'7%',alignItems: 'center',justifyContent: 'center',width:'25%',margin:'auto'}}>
                      <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                      </div>
                      :
                    <div>
                     {
                       productData.length === 0 ? 
                       <Typography variant="h6" textAlign="center" margin="25px 0">Product Not Found</Typography>
                      :
                      <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: 'center', flexWrap: "wrap", paddingBottom: 20, marginBottom: 30, width: '100%' }}>
                          {productData.map(prod => (
                            
                                  <ProductCard prod={prod} to={`/Detail/type/category/${prod._id}`} key={prod._id}  sendData={sendData}/>
                          ))}
                      </Container>
                     }
                        
                    </div>
                  }
                  </div>

               </>
                :  step.page === 3 ?
                 <>
                 <div className="adminSection-close">
                     <Button variant='contained'  color='error'   style={{marginRight:'1%',marginTop:'3%'}} onClick={() => handleBack()} endIcon={<AiFillCloseCircle />}>Close</Button>
                  </div>
                   <div className='collection-detail'>
                        <div className='collection-detail-show'>
                        {
                          section == null?
                          null
                          :
                          <div >
                            <div>
                            </div>
                              {loading ? (
                                <Skeleton variant='rectangular' height={400} />
                                 ) : (
                                <div className="product-image">
                                {
                                  images.length  === 0 ?
                                  null
                                  :
                                  <ImageGallery items={images} />
                                }
                                    
                                </div>
                            )} 
                          </div>
                          }
                          {
                            seletedData === null?
                            null
                            :
                            <div style={{width:'95%'}}>
                                <h3>{section.name}</h3>  
                                <Rating name="read-only" value={Math.round(section.rating)} size='small'  readOnly precision={0.5} /> 
                                <br/>
                               
                          </div>
                          }
                          
                        </div>

                        {
                          seletedData == null?
                          null
                            :
                          <div className='collectionDetail-list'>
                            
                            
                              <div className='collectionDetail-listName'>
                                  <h3>{seletedData.name} </h3>
                                  
                              </div>
                             
                                {
                                  loadingData === true?
                                      <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'10%',margin:'auto'}}>
                                            <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                      </div>
                                    :
                                    <div className='collectionDetail-list-sub'>
                                    {
                                      addProduct.map((data , index)=> (
                                        <div className='collectionDetail-product'>
                                            <a href='#' onClick={()=>ClickToShow(data)}>
                                              <div className='collectionDetail-product-img'>
                                                    <img src={data.images[0].url}/> 
                                                </div>
                                              </a>  
                                                <h6>  {data.name.length > 35 ? data.name.slice(0, 35) + '...' : data.name}</h6>
                                                <h6>  {moneyFormat(data.price)}</h6>
                                                <p><a href="#" onClick={()=>removeProductData(data,index)}><AiFillDelete style={{ fontSize: 23 }} color='red'/> </a></p>
                                                <Rating name="read-only" value={Math.round(data.rating)} size='small'  readOnly precision={0.5} /> 
                                        </div>
                                        ))
                                      }
                                    </div>
                                  }
                             
                                
                                     
                                  </div>
                           }

                        
                              
                            
                        
                   </div>
               </>
             :
             null
           }
             
       </div>

  )
}

export default AdminSection